.header-basic {
    background-color: #292c2f;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
    padding: 20px 40px;
    height: 80px;
    box-sizing: border-box;
}

.header-basic .header-limiter {
    max-width: 1500px;
    text-align: center;
    margin: 0 auto;
}

/* Logo */

.header-basic .header-limiter h1 {
    float: left;
    font: normal 28px Cookie, Arial, Helvetica, sans-serif;
    line-height: 40px;
    margin: 0;
}

.header-basic .header-limiter span {
    color: #ffffff;
    text-decoration: none;
}

.header-basic .header-limiter a {
    color: #ffffff;
    text-decoration: none;
}

.header-basic .header-limiter nav {
    font: 16px Arial, Helvetica, sans-serif;
    line-height: 40px;
    float: right;
}

.header-basic .header-limiter nav span{
    display: inline-block;
    padding: 0 5px;
    text-decoration:none;
    color: #ffffff;
    font-size: 16px;
    opacity: 0.9;
}

.header-basic .header-limiter span:hover{
    opacity: 1;
    cursor: pointer;
}

.header-basic .header-limiter span.selected {
    color: #608bd2;
    pointer-events: none;
    opacity: 1;
}

/* Making the header responsive */

@media all and (max-width: 600px) {

    .header-basic {
        padding: 20px 0;
        height: 75px;
    }

    .header-basic .header-limiter h1 {
        float: none;
        margin: -8px 0 10px;
        text-align: center;
        font-size: 24px;
        line-height: 1;
    }

    .header-basic .header-limiter nav {
        line-height: 1;
        float:none;
    }

    .header-basic .header-limiter nav span {
        font-size: 13px;
    }

}

/* For the headers to look good, be sure to reset the margin and padding of the body */

body {
    margin:0;
    padding:0;
}
