button {
  background-color: cornflowerblue;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  margin: 10px;
}

.content {
  max-width: 1500px;
  margin-left: 50px;
}

.title {
  text-align: center;
}

.bold {
  font-weight: bold;
}

a {
  color: white;
  text-decoration: none;
}

.red {
  color: red;
}

.green {
  color: green;
}