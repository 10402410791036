label {
    margin: 10px;
}

textarea {
    margin: 10px;
}

input {
    margin-left: 10px;
}