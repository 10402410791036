.climax {
    background-image: url(../../../images/climax_tracker.jpg);
    width: 95vw;
    height: 90vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 10px;
}

.title-climax {
    font-size: 66px;
}

.bottom-left {
    position:absolute;
    top: 65%;
    left: 8%;
}

.bottom-right {
    position:absolute;
    top: 65%;
    left: 80%;
}
