.button-list {
    background: lightblue;
    border-radius: 25px;
    padding: 20px;
    width: 40%;
    text-align: center;
}

.graph-title {
    text-align: center;
    text-decoration: underline;
    font-weight: bold;
}